export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://x.com/Herb_Protocol",
  mail: "mailto:support@herb.finance",
  discord: "",
  scan: "",
  telegram: "https://t.me/Herb_Protocol",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
};
