import React from "react"

import "./Ecosystem.scss"

export const Ecosystem = (): JSX.Element => {
  const blocks = [
    {
      title: "IPFS Integration",
      desc: "By utilizing the InterPlanetary File System (IPFS), Herb Finance ensures a decentralized and resilient storage solution for important data, enhancing the overall stability and reliability of our platform.",
    },
    {
      title: "Chainlink Partnership",
      desc: "Our collaboration with Chainlink provides secure and accurate price feeds through decentralized oracle networks, ensuring the integrity of our staking platform and safeguarding users' assets.",
    },
    {
      title: "Security Audits",
      desc: "To maintain the highest security standards, Herb Finance undergoes routine security audits conducted by industry-leading experts. These audits help us identify and address potential vulnerabilities, ensuring the ongoing safety and reliability of our platform.",
    },
  ]
  return (
    <div className="ecosystem">
      <h3 data-aos="zoom-in" className="ecosystem-title">Herb is platform you can trust</h3>
      <div data-aos="zoom-in" className="ecosystem-content">
        {blocks.map((block, index) => (
          <div key={index} className="ecosystem-content-item">
            <div className="ecosystem-content-item-title">{block.title}</div>
            <div className="ecosystem-content-item-desc">{block.desc}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
