import React, {useEffect, useState} from "react"
import { Header, Footer } from "components"
import "./Swap.scss"
// @ts-ignore
import { SwapWidget, darkTheme } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css';
import {useAccount, useConnect, useDisconnect, useWalletClient} from 'wagmi';
import {tokenList} from "./tokenList";
import {ethers} from "ethers";

export const Swap = (): JSX.Element => {
  const jsonRpcUrlMap = {
    1: ['https://eth-mainnet.g.alchemy.com/v2/adUCkQLHeotIto_CrVwkvMIxM7o5RE4E'],
    56: [`https://bsc-dataseed.binance.org/`],
    42161: [`https://arb1.arbitrum.io/rpc`]
  }

  return (
    <div className="swap-other">
      <Header />
      <div className="swap-other-content">
        <h3 className="swap-other-content-title">Swap</h3>
        <div className="swap-other-content-data">
          <iframe
            src="https://app.uniswap.org/#/swap?theme=light&exactField=input&exactAmount=10&inputCurrency=0x6b175474e89094c44da98b954eedeac495271d0f"
            height="660px"
            width="100%"
          />
          {/*<SwapWidget*/}
          {/*  jsonRpcEndpoint={JSON_RPC_URL}*/}
          {/*  tokenList={tokenList}*/}
          {/*  // locale={locale}*/}
          {/*  className='swap-other-content-data-widget'*/}
          {/*  width={`100%`}*/}
          {/*/>*/}
        </div>
      </div>
      <Footer/>
    </div>
  )
}
