import React, { useEffect, useState } from "react"

import "./Main.scss"
import { Link, useSearchParams } from "react-router-dom"
import { getFromLocalStorage, routes } from "../../utils"
import { useMedia } from "use-media"
import {preloadDataBsc} from "../../pages/SwapInfo/preloadDataBsc";
import {preloadData} from "../../pages/SwapInfo/preloadData";
import img from "assets/images/main-img.png"


export const Main = (): JSX.Element => {
  return (
    <main className="main">
      <div className='main-left'>
        <h1 className="main-left-title">
          Experience <span>Herb Staking</span>, <br/>
          your gateway to unlimited<br/>
          <span>earning in staking</span>
        </h1>
        <p>
          An exception occurs when cupid&apos;s arrow is not produced, because pleasure exists, whether it is due to resentment or flight, but because they result in great pain, which is due to the logic of the act of desiring.
        </p>
      </div>
      <img src={img} alt='main image' />
    </main>
  )
}
