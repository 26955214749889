import React, { useEffect, useState } from "react"
import cn from "classnames"
import { useMedia } from "use-media"
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom"

import { hrefs, mixins, routes, useScrollPosition} from "utils"

import "./Header.scss"
import { ReactComponent as Polygon } from "assets/icons/polygon.svg"
import LogoMobile from "assets/icons/logo-small.svg"
import { ReactComponent as Logo } from "assets/icons/logo.svg"
import { ReactComponent as Menu } from "assets/icons/menu.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"
import { ReactComponent as IconAssets } from "assets/icons/header/assets.svg"
import { ReactComponent as IconPools } from "assets/icons/header/pools.svg"
import { ReactComponent as IconReferral } from "assets/icons/header/referral.svg"
import { ReactComponent as IconSwap } from "assets/icons/header/swap.svg"
import { ReactComponent as IconAnalytics } from "assets/icons/header/analytics.svg"
import { ReactComponent as IconFaq } from "assets/icons/header/faq.svg"

import { AccountDropdown } from "../AccountDropdown"
import { NetworkDropdown } from "../NetworkDropdown"
import { WalletModal} from "../../modals"
import {useAccount} from "wagmi";
import { ConnectKitButton } from 'connectkit';
import {getChainId} from "@wagmi/core";
import {config} from "../../index";

export const Header = (): JSX.Element => {
  const scroll = useScrollPosition()
  const [search, setSearch] = useSearchParams()
  const [sidebar, setSidebar] = useState(false)
  const [wallet, setWallet] = useState(false)
  const { address } = useAccount();
  const chainId = getChainId(config)
  const isM = useMedia({ maxWidth: mixins.m })
  const navigate = useNavigate()
  const location = useLocation()

  const toggleSidebar = () => setSidebar(!sidebar)

  const links = [
    {
      title: "Home",
      link: `${routes.index}?${search.toString()}`,
      defaultLink: routes.index,
      icon: <IconPools />
    },
    {
      title: "Pools",
      link: `${routes.farms}?${search.toString()}`,
      defaultLink: routes.farms,
      icon: <IconPools />
    },
    {
      title: "Staking Assets",
      link: `${routes.pool}?${search.toString()}`,
      defaultLink: routes.pool,
      icon: <IconAssets />
    },
    {
      title: "Referral",
      link: `${routes.referral}?${search.toString()}`,
      defaultLink: routes.referral,
      icon: <IconReferral />
    },
    {
      title: "Analytics",
      link: `${routes.swapInfo}?${search.toString()}`,
      defaultLink: routes.swapInfo,
      icon: <IconAnalytics />
    },
    {
      title: "Swap",
      link: `${routes.swap}?${search.toString()}`,
      defaultLink: routes.swap,
      icon: <IconSwap />
    },
    {
      title: "FAQ",
      link: `${routes.faq}?${search.toString()}`,
      icon: <IconFaq />
    },
  ]

  return (
    <header
      className={cn("sidebar", {
        show: sidebar,
        offset: scroll > 30,
        dark: true
      })}
    >
      <div className="sidebar-header">
        {(!isM) && (
          <Link to={`${routes.index}?${search.toString()}`} className={cn('sidebar-header-desk', {
            big: !!address
          })}>
            <Logo className="sidebar-header-logo" />
          </Link>
        )}
        {isM && (
          <div className='sidebar-header-left-mobile'>
            <button className="sidebar-header-btn" onClick={toggleSidebar}>
              {sidebar ? <Close/> : <Menu/>}
            </button>
            <Link to={`${routes.index}?${search.toString()}`} className="sidebar-header-mobile">
              <Logo className="sidebar-header-logo" />
            </Link>
          </div>
        )}
        {isM && (
          <div className="sidebar-header-right">
            <>
              {!!address?.length && <NetworkDropdown chainId={chainId} account={address}/>}
              {!!address?.length ? (
                <AccountDropdown account={address} openWallet={() => setWallet(true)}/>
              ) : (
                <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
              )}
            </>
          </div>
        )}
      </div>
      {isM && (
        <div
          className={cn("header-sidebar", {
            active: sidebar,
          })}
        >
          {(links).map((link, index) =>
            link.link ?
              <button
                onClick={() => {
                  navigate(link.link || "")
                  setSidebar(false)
                }}
                key={index}
                className={cn('sidebar-left-block-item', {
                  active: location.pathname.match(link.link),
                })}
              >
                {link.icon}
                {link.title}
              </button> : (
                <a
                  // href={link.href}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={index}
                  className={cn('sidebar-left-block-item', {
                    // disabled: link.disabled
                  })}
                >
                  {link.icon}
                  {link.title}
                </a>
              )
          )}
        </div>
      )}
      {(!isM) && (
        <div className='sidebar-right-content'>
          {links.map((link, index) =>
            link.link ? (
              <button
                onClick={() => {
                  navigate(link.link || "")
                  setSidebar(false)
                }}
                key={index}
                className={cn('sidebar-navigation-item', {})}
              >
                {link.title}
                <div className="sidebar-navigation-item-coming">
                  <Polygon/>
                  Coming Soon...
                </div>
              </button>
            ) : (
              <a
                // href={link.href}
                target="_blank"
                rel="noreferrer noopener"
                key={index}
                className={cn('sidebar-navigation-item', {
                })}
              >
                {link.title}
                <div className="sidebar-navigation-item-coming">
                  <Polygon/>
                  Coming Soon...
                </div>
              </a>
            ),
          )}
        </div>
      )}
      {!isM && (
        <div className="sidebar-right">
          <>
            {!!address?.length && <NetworkDropdown chainId={chainId} account={address}/>}
            {!!address?.length ? (
              <AccountDropdown account={address} openWallet={() => setWallet(true)}/>
            ) : (
              <div className='connectkit-btn'><ConnectKitButton label='Connect Wallet'/></div>
            )}
          </>
        </div>
      )}
      {wallet && <WalletModal onClose={() => setWallet(false)}/>}
    </header>
  )
}
