import React, {useState} from "react"

import { Medias, routes } from "utils"

import imgLogo from "assets/icons/logo-white.svg"
import "./Footer.scss"
import { Link, useSearchParams } from "react-router-dom"
import {ConnectKitButton} from "connectkit";

export const Footer = (): JSX.Element => {
  const [search] = useSearchParams()

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className='footer-left'>
          <img src={imgLogo} alt='logo' className='footer-left-logo'/>
          <div className="footer-med-links">
            <Link className="footer-med-item" onClick={() => {
              window.scrollTo({
                top: 0,
              })
            }} to={`${routes.privacy}?${search.toString()}`}>
              Privacy Policy
            </Link>
            <Link className="footer-med-item" onClick={() => {
              window.scrollTo({
                top: 0,
              })
            }} to={`${routes.terms}?${search.toString()}`}>
              Terms & Conditions
            </Link>
            <Link className="footer-med-item" onClick={() => {
              window.scrollTo({
                top: 0,
              })
            }} to={`${routes.cookies}?${search.toString()}`}>
              Cookie Policy
            </Link>
          </div>
        </div>
        <div className='footer-right'>
          <Medias/>
          <div className='connectkit-btn-white'><ConnectKitButton label='Connect Wallet'/></div>
        </div>
      </div>
      <div className='footer-bottom'>
        <p className="footer-reserved">Copyright © 2024 Herb Finance. All rights reserved</p>
        <address>
          6789 Sunset Boulevard, Suite 1010, Los Angeles, CA 90028 USA
        </address>

      </div>
    </footer>
  )
}
